<template>
    <div>
        <el-tabs  v-model="activeTab" >
            <el-tab-pane name="tab01"><span slot="label"><i class="el-icon-menu"></i> 模块管理 </span></el-tab-pane>                       
            <el-tab-pane name="tab03"><span slot="label"><i class="el-icon-takeaway-box"></i> 模块子菜单管理 </span></el-tab-pane>
            <el-tab-pane name="tab02"><span slot="label"><i class="el-icon-tickets"></i> 模块表单明细管理 </span></el-tab-pane> 
        </el-tabs>
        <span v-if="activeTab=='tab01'">
            <div class="one-toolbar" style=" margin-top:-12px; ">
                <span class="spanRow" >
                    <el-button type="text" size="small" @click="addRec()"  ><i class="el-icon-circle-plus" style="margin-right:5px;color:white"></i><span style="color:white">新增</span></el-button>
                    <el-button type="text" size="small"  @click="editRec()"  ><i class="el-icon-edit" style="margin-right:5px;color:white"></i><span style="color:white">修改</span></el-button>
                    <el-button type="text" size="small"  @click="delRec()"  ><i class="el-icon-delete" style="margin-right:5px;color:white"></i><span style="color:white">删除</span></el-button>                      
                    <el-input :placeholder="input_hold" v-model="textparam" size="small" @keyup.enter.native="qryTabData" style="width:350px;margin-left:10px;vertical-align:center">
                        <el-select v-model="selparam" slot="prepend" placeholder="请选择列名" clearable @change="elchange" style="width:150px; ">
                            <span v-for="item in tabCol" :key='item.key'>
                                <el-option :label="item.title" :value="item.key"></el-option>
                            </span>
                        </el-select>
                    </el-input> 
                </span>                    
            </div> 
            <div >
                <el-table  :data="baseData"                  
                    border                            
                    @row-click="rowClick"
                    size="small"
                    ref="refmod"
                    row-key="IDSEQ"
                    :height="tableHeight"
                    highlight-current-row>

                    <el-table-column   v-for="(item ) in tabCol" :key="item.index"
                        :prop="item.key"
                        :label="item.title"    
                        show-overflow-tooltip
                        :align="item.align"                                  
                        :sortable="item.key!=='SORTBY'&&item.EDCSS!=='checkbox'"
                        :min-width="item.width">
                        <template   slot-scope="scope" >
                            <el-checkbox v-if ="item.EDCSS==='checkbox'"  disabled true-label="Y"  false-label="N" v-model="scope.row[item.key]" ></el-checkbox>
                            <span v-else v-text="scope.row[item.key]"></span>
                        </template>
                    </el-table-column>      
  
                </el-table>
                <el-dialog  :show-close="false" v-dialogDrag :visible.sync="addModal" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
                    <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
                        <span style="line-height:40px;height:40px;font-size:16px;padding-left:5px;">{{type==='create'?'新增记录':'编辑记录'}}</span>
                    </div>
 
                    <Form ref="addForm" :model="formCol" :label-width="100"  :rules="ruleValidate"  label-position="right" inline >
                        <FormItem  :label="item.FLDNM"  v-for="(item,index) in tabCol" :key="index" :prop="item.FLDNO" class="item-two">
                            <el-checkbox border v-if="item.EDCSS==='checkbox'" true-label="Y"  false-label="N" v-model="formCol[item.FLDNO]"  style="width:100%"></el-checkbox>
                            <el-input type="text" v-else :disabled="item.DISABLED=='Y'?true:false||index==0" v-model="formCol[item.FLDNO]"  style="width:100%" clearable/>
                        </FormItem>
                    </Form>
                    <div slot="footer">
                        <el-button type="info" size="small" style="margin-right:30px"  @click="addModal=false">取消</el-button>
                        <el-button type="primary" size="small"   @click="modal_ok">确定</el-button>
                    </div>
                </el-dialog>                  
            </div> 
        </span>
        <span v-else-if="activeTab=='tab02'">
            <div style="margin-top:-12px"><sysForm /></div>
        </span>
        <span v-else-if="activeTab=='tab03'">
            <div style="margin-top:-12px"><modForm/></div>
        </span>
        
    </div>
</template>

<script>
    import { getTabColOrData } from '../../api/user';
    import sysForm from './sys_form'
    import modForm from './mod_form'
    export default {
        name: "sys_mod",
        components: {
          sysForm ,  
          modForm,
        },
        data () {
            return {
                activeTab:'tab01',
                addModal:false, //modal 
                textparam:'', // input 绑定值
                selparam:'',// select 绑定值
                tabCol:[] ,//表列集合
                input_hold:'请输入内容...',
                v_url:this.$store.state.baseUrl+'sysprivs/getTableRecMany', //api请求路径
                v_likeurl:this.$store.state.baseUrl+'sysprivs/getLikeManyRec', //多记录查询 like模式
                v_urlcol:this.$store.state.baseUrl+'sysprivs/getFormColumn', //获取列集合
                v_username:this.$cookies.get('v_username'), //api 请求用户
                v_password:this.$cookies.get('v_password') ,//api 请求密码
                baseData:[] ,//表数据
                allData:[] , //保存取到所有数据
                formCol:{}, //表格单行记录 model
                rowIndex:'-1', //行号 
                // 每页显示多少条
                pageSize:25,
                pageNum:1,
                v_many:this.$store.state.manyUrl , //api请求路径 
                // 初始化信息总条数
                dataCount:0,
                type:'create',
                loading:false,// table加载bool
                rowIdseq:'-1',
                ruleValidate: {},
                notNull:[],
                stfgBool:true,
            }
        },
        computed: {
            tableHeight(){
                return (window.innerHeight - 160) +'px'
            }               
        },
        created () {
            this.getTabCol()
            this.getModData('','','','','','','')
        },
        mounted() {
            //动态生成表单验证规则
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','18','fty','HEAD','NOTNULL','Y').then((res) => {
                let ruleObj={}
                let ruleVal=[]
                this.notNull = res.data
                res.data.forEach(item => {
                    let v_fldnm =item.FLDNM
                    let obj1={required:true,message:v_fldnm+'不能为空'}
                    ruleVal =[]
                    ruleVal.push(obj1)
                    ruleObj[item.FLDNO]=ruleVal                  
                });
                this.ruleValidate =ruleObj
            });
 
        },
        methods: {
 
            //确定
            modal_ok(){
                var vm=this;
                this.notNull.some(item=>{  //forEach 没找到跳出循环，some：return true 跳出lop ,every：return false 跳出
                    if (!this.formCol[item.FLDNO] ){
                       this.stfgBool =false 
                       this.$Message.error(item.FLDNM+'不能为空'); 
                       return true
                    }else{
                      this.stfgBool =true  
                    }
                })
                //发起保存请求   
                if (this.stfgBool)       
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/formOneSave?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_json:JSON.stringify(vm.formCol), p_table:'sys_mod'},
                }).then(res=>{      
                    if(res.data.result =='OK')  {          
                        vm.addModal =false   
                        vm.getModData('','',this.selparam,this.textparam,'','' ,'');    
                    }else{
                        this.$Message.error(res.data.result)
                    }        
                }) 
            } ,
            //获取单行表单字段
            getFormCol(){
                var vm=this
                getTabColOrData(vm.v_urlcol,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','18','fty','HEAD','thide','N').then((res) => {
                    vm.formCol = res.data
                });
            },
            //获取表数据
            getModData(v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_){
                var vm=this
                this.loading =true
                getTabColOrData(this.v_likeurl,this.v_username,this.v_password,'V_SYS_MODULE',v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_).then((res) => {
                    vm.baseData = res.data.result
                    //保存取到 所有数据
                    // vm.allData =res.data.result
                    // vm.dataCount =res.data.result.length //总条数
                    // // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
                    // if (res.data.result.length < vm.pageSize){
                    //     vm.baseData = vm.allData
                    // }else{
                    //     vm.baseData =vm.allData.slice(0,vm.pageSize) //返回选定元素的子数组，不包含结尾元素
                    // }
                
                    this.loading =false
                    this.$nextTick(()=>{
                        this.$refs['refmod'].doLayout()
                    })                  
                    //setTimeout(()=>{this.loading =false},2000) //测试效果
                }).catch(function(err){
                    this.$Message.error(err)
                })                 
            },
            //表格页面导航
            changePage(index){
                var _start = ( index - 1 ) * this.pageSize;
                var _end = index * this.pageSize;
                this.pageNum =index
                this.baseData = this.allData.slice(_start,_end);
            },
            //获取表行数据
            rowClick (row, index) {
                this.formCol = JSON.parse(JSON.stringify(row)) //获取行数据
                this.rowIndex =index
                this.rowIdseq =row.IDSEQ
            },
            //获取表列头字段
            getTabCol(){
                var vm=this
                getTabColOrData(vm.v_many,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','18','fty','HEAD','thide','N',' order by sortby,lstseq').then((res) => {
                    if (res.data.code==='200'){
                        vm.tabCol = res.data.result
                    }else{
                        this.$Message.info('系统断开连接，请重新登录') 
                    }
                }) 
            },
            //查询事件
            qryTabData(){
                //console.log('....'+this.selparam+'::'+this.textparam)
                this.getModData('','',this.selparam,this.textparam,'','','' );
            },
            // el-select 改变时 影响el-input 的 placeholder
            elchange(val){  // 这个val 就是 el-select 的 value
                let obj ={}
                obj =this.tabCol.find(item=>{
                    return item.key===val
                })
                this.input_hold='请输入'+obj.title+' 的值'
            },
            //新增
            addRec(){
                this.addModal =true
                this.type ='create'
                //this.$refs.table.clearCurrentRow()
 
                this.formCol ={ISFRONT:'N'}
                this.rowIndex ='-1'
            },
            //修改
            editRec(){
                if (this.rowIndex==='-1'){
                    this.$Message.error('请选择一条记录')
                }else{
                    this.addModal =true
                    this.type ='edit'
                }
            },
            //删除
            delRec(){
                if (this.rowIdseq==='-1'){
                    this.$Message.error('请选择一条记录')
                }else{
                    this.$Modal.confirm({
                        title: '删除确认',
                        content: '<p>你确定要删除此行记录吗</p>',
                        onOk: () => {
                            //发起删除请求
                            var vm=this;
                            this.$axios({
                                method: 'post',
                                url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                                data: {p_key:'idseq',p_value:this.rowIdseq,p_table:'sys_mod'},
                            }).then(res=>{      
                                if(res.data.code =='200')  {           
                                    vm.getModData('','',this.selparam,this.textparam,'','','' );     
                                }else{
                                    this.$Message.error(res.data.message)
                                }        
                            }) 
                        }
                    });
                }
            },
        }
    }
</script>
<style lang="less" scoped>
    .item-two  {
        width: 48%;
        color:#409EFF;
    }
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
</style>
<style lang="less">
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
</style>
 
