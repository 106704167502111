<template> 
    <div>
        <div class="one-toolbar" style=" min-width:1000px; "> 
            <span class="spanRow">
            <el-button type="text" size="small" @click="addRec()"  ><i class="el-icon-circle-plus" style="margin-right:5px;color:white"></i><span style="color:white">新增</span></el-button>
            <el-button type="text" size="small"  @click="editRec()"  ><i class="el-icon-edit" style="margin-right:5px;color:white"></i><span style="color:white">修改</span></el-button>
            <el-button type="text" size="small"  @click="delRec()"  ><i class="el-icon-delete" style="margin-right:5px;color:white"></i><span style="color:white">删除</span></el-button>                     
            <el-input :placeholder="input_hold" @keyup.enter.native="qryTabData" v-model="textparam" size="small" style="width:350px;margin-left:10px;vertical-align:center">
                <el-select v-model="selparam" slot="prepend" placeholder="请选择列名" clearable @change="elchange" style="width:150px; ">
                    <span v-for="item in tabCol2" :key='item.key'>
                        <el-option :label="item.title" :value="item.key"></el-option>
                    </span>
                </el-select>
            </el-input> 
            <span  style="margin-left:10px; ">
                <el-radio-group v-model="chkval" size="small" @change="chkChange">
                    <el-radio-button label="ALL">所有</el-radio-button>
                    <el-radio-button label="NO">无路由表单</el-radio-button>
                    <el-radio-button label="YES">有路由表单</el-radio-button>
                </el-radio-group>
            </span>
            </span>
        </div>
        <div style="margin-top:10px;">
            <Table  :data="baseData2" 
                    :columns="tabCol2" 
                    border                            
                    disabled-hover
                    style="overflow:auto"
                    :loading="loading"
                    size="small"
                    ref="frmtable"
                    highlight-row
                    @on-row-click="rowClick2"
                    @on-sort-change="sortChanged2" 
                    :height="390"
                    class="small-table" 
                    >
            </Table>
        </div>  
        <el-tabs  v-model="activeTab" >
            <el-tab-pane name="tab01">
                <span slot="label"><i class="iconfont icon-yuandianxiao"></i> 表单编码生成规则
                    <el-button type="text" size="small" @click="addRec2()"  ><i class="el-icon-circle-plus" style="margin-right:5px; "></i> 新增 </el-button>
                    <el-button type="text" size="small"  @click="editRec2()"  ><i class="el-icon-edit" style="margin-right:5px; "></i> 修改 </el-button>
                    <el-button type="text" size="small"  @click="delRec2()"  ><i class="el-icon-delete" style="margin-right:5px; "></i> 删除 </el-button> 
                </span>
            </el-tab-pane>
            <el-tab-pane name="tab02"><span slot="label"><i class="iconfont icon-yuandianxiao"></i> 表单流程设置</span></el-tab-pane>            

        </el-tabs>  
        <span v-if="activeTab=='tab01'">
            <Table  :data="genData" 
                    :columns="genCol" 
                    border                            
                    disabled-hover
                    style="overflow:auto"
                    :loading="loading"
                    size="small"
                    ref="gentable"
                    highlight-row
                    @on-row-click="rowClick3"
                    :height="tabHeight"
                    class="small-table" 
                    >
            </Table>
        </span>
                <el-dialog  :show-close="false" v-dialogDrag :visible.sync="addModal2" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
                    <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
                        <span style="line-height:40px;height:40px;font-size:16px;padding-left:5px;">{{type==='create'?'新增记录':'编辑记录'}}</span>
                    </div>  
                    <Form ref="addForm" :model="formCol2" :label-width="100"  :rules="ruleValidate"  label-position="right" inline >
                        <FormItem  :label="index==0?'序号':item.FLDNM"  v-for="(item,index) in tabCol2" :key="index" :prop="item.FLDNO" class="item-three">
                            <el-checkbox border  v-if="item.EDCSS==='checkbox'" true-label="Y"  false-label="N" v-model="formCol2[item.FLDNO]"  style="width:100%"></el-checkbox>
                            <el-input v-else type="text" :disabled="item.DISABLED=='Y'?true:false||index==0" v-model="formCol2[item.FLDNO]"  clearable/>
                        </FormItem>
                    </Form>
                    <div slot="footer" >
                        <el-button type="info"  size="small"  @click="modal_cancel" style="margin-right:25px;">取消</el-button>
                        <el-button type="primary" size="small"  @click="modal_ok">确定</el-button>
                    </div>
 
                </el-dialog> 
                <el-dialog  :show-close="false" v-dialogDrag :visible.sync="genModal" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
                    <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
                        <span style="line-height:40px;height:40px;font-size:16px;padding-left:5px;">{{type2==='create'?'新增记录':'编辑记录'}}</span>
                    </div>  
 
                    <Form ref="genForm" :model="genFormCol" :label-width="100"  :rules="ruleValidate2"  label-position="right" inline >
                        <FormItem  :label="item.FLDNM"  v-for="(item,index) in genFCol" :key="index" :prop="item.FLDNO" class="item-three">
                            <el-input-number controls-position="right" v-if="item.EDCSS==='number'" v-model="genFormCol[item.FLDNO]" style="width:100%" clearable/> 
                            <el-select v-else-if="item.EDCSS==='select' && item.FLDNO==='ITMNO'" v-model="genFormCol[item.FLDNO]" style="width:100%" clearable>                            
                                <el-option v-for="(subitem,index) in itmArr" :value="subitem.CODE" :key='index'>{{subitem.CNAME}}</el-option>   
                            </el-select>
                            <el-select v-else-if="item.EDCSS==='select' && item.FLDNO==='DTTMFMT'" v-model="genFormCol[item.FLDNO]" style="width:100%" clearable>                            
                                <el-option v-for="(subitem,index) in dateArr" :value="subitem.CODE" :key='index'>{{subitem.CNAME}}</el-option>   
                            </el-select>
                            <el-input type="text" v-else :disabled="item.DISABLED=='Y'?true:false||index==0" v-model="genFormCol[item.FLDNO]"   clearable/>
                        </FormItem>
                    </Form>
                    <div slot="footer"  >
                        <el-button type="info" size="small"  @click="modal_cancel2" style="margin-right:25px;">取消</el-button>
                        <el-button type="primary" size="small"   @click="modal_ok2">确定</el-button>
                    </div>
 
                </el-dialog>              
                
    </div>                        
</template>

<script>
    import { getTabColOrData } from '../../api/user';
    import {itmArray,dateArray} from '../../api/Select'
    export default{
        data () {
            return {
                activeTab:'tab01',
                addModal2:false, //modal 
                genModal:false, //modal 
                textparam:'', // input 绑定值
                chkval:'ALL',
                selparam:'FRMNM',// select 绑定值
                tabCol2:[] ,//表列集合
                input_hold:'请输入内容...',
                baseData2:[] ,//表数据
                genData:[] ,//生成编码表数据
                genCol:[] ,//生成编码表 列集合
                genFCol:[] ,//生成编码表 列集合
                allData:[] , //保存取到所有数据
                formCol2:{}, //表格单行记录 model
                genFormCol:{}, //表格单行记录 model
                rowIndex:'-1', //行号 
                rowIndex2:'-1', //行号 
                // 每页显示多少条
                pageSize:20,
                pageNum:1,
                // 初始化信息总条数
                dataCount:0,
                type:'create',
                type2:'create',
                loading:false,// table加载bool
                rowIdseq:'-1',
                rowIdseq2:'-1',
                ruleValidate: {},
                ruleValidate2: {},
                notNull:[],
                notNull2:[],
                lstseq:'',
                stfgBool:true,
                v_url:this.$store.state.baseUrl+'sysprivs/getTableRecMany', //api请求路径
                v_likeurl:this.$store.state.baseUrl+'sysprivs/getLikeManyRec', //多记录查询 like模式
                v_username:this.$cookies.get('v_username'), //api 请求用户
                v_password:this.$cookies.get('v_password') ,//api 请求密码
                itmArr:[] , //编码数组
                dateArr:[], //日期格式
            }
        },
        computed: {
            tabHeight:function(){
                return (window.innerHeight- 610) //+ 'px'
            } 
        },
        mounted () {
            //编码类型
            itmArray().then((res)=>{
                this.itmArr =res.data.result
            });
            //日期格式
            dateArray().then((res)=>{
                this.dateArr =res.data.result
            });
 
            //动态生成表单验证规则
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','18','fty','HEAD2','NOTNULL','Y').then((res) => {
                let ruleObj={}
                let ruleVal=[]
                this.notNull = res.data
                res.data.forEach(item => {
                    let v_fldnm =item.FLDNM
                    let obj1={required:true,message:v_fldnm+'不能为空'}
                    ruleVal =[]
                    ruleVal.push(obj1)
                    ruleObj[item.FLDNO]=ruleVal                  
                });
                this.ruleValidate =ruleObj
            });
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','18','fty','HEAD4','NOTNULL','Y').then((res) => {
                let ruleObj={}
                let ruleVal=[]
                this.notNull2 = res.data
                res.data.forEach(item => {
                    let v_fldnm =item.FLDNM
                    let obj1={required:true,message:v_fldnm+'不能为空'}
                    ruleVal =[]
                    ruleVal.push(obj1)
                    ruleObj[item.FLDNO]=ruleVal                  
                });
                this.ruleValidate2 =ruleObj
            });
        },
        created () {
          this.getTabCol2()  
          this.getFormData('','','','','','','')
          //明细表
          this.getGenCol()  
          this.getGenCol2() 
          this.getGenData('','','','','','','')
        },
        methods: {
            //表单过滤 有无路由
            chkChange(){
                if(this.chkval==='ALL'){
                    this.getFormData('','',this.selparam,this.textparam,'','','')
                }else if(this.chkval==='NO'){
                    this.getFormData('','',this.selparam,this.textparam,'','',' AND FRMURL IS NULL')
                }if(this.chkval==='YES'){
                    this.getFormData('','',this.selparam,this.textparam,'','',' AND FRMURL IS NOT NULL')
                }
            }, 
            //明细单  取消
            modal_cancel2(){
                this.$refs.gentable.clearCurrentRow()
                this.genModal=false
                this.rowIndex2 ='-1'
            },
            //明细表单  确定
            modal_ok2(){
                var vm=this;
                this.notNull2.some(item=>{  //forEach 没找到跳出循环，some：return true 跳出lop ,every：return false 跳出
                    if (!this.genFormCol[item.FLDNO] ){
                       this.stfgBool =false 
                       this.$Message.error(item.FLDNM+'不能为空'); 
                       return true
                    }else{
                      this.stfgBool =true  
                    }
                })
                console.log(vm.rowIdseq+".."+JSON.stringify(vm.genFormCol))
                //发起保存请求   
                if (this.stfgBool)       
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/tableBodySave?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_json:JSON.stringify(vm.genFormCol), p_table:'sys_form_gen',p_idseq:'',p_user:vm.$cookies.get('v_username')},
                }).then(res=>{      
                    if(res.data.result =='OK')  {          
                        vm.genModal =false  
                        if (vm.type2==='edit'){
                            vm.genData.splice(vm.rowIndex2,1,vm.genFormCol)
                        } else{
                          vm.getGenData('idseq',this.rowIdseq,'','','','' ,' order by lstseq desc');    
                        }
                    }else{
                        this.$Message.error(res.data.result)
                    }        
                }) 
            } ,
            //主表单  取消
            modal_cancel(){
                this.$refs.frmtable.clearCurrentRow()
                this.addModal2=false
                this.rowIndex ='-1'
            },
            //确定
            modal_ok(){
                var vm=this;
                this.notNull.some(item=>{  //forEach 没找到跳出循环，some：return true 跳出lop ,every：return false 跳出
                    if (!this.formCol2[item.FLDNO] ){
                       this.stfgBool =false 
                       this.$Message.error(item.FLDNM+'不能为空'); 
                       return true
                    }else{
                      this.stfgBool =true  
                    }
                })
                //发起保存请求   
                if (this.stfgBool)       
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/formOneSave?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_json:JSON.stringify(vm.formCol2), p_table:'sys_form'},
                }).then(res=>{      
                    if(res.data.result =='OK')  {          
                        vm.addModal2 =false  
                        if (vm.type==='edit'){
                            vm.baseData2.splice(vm.rowIndex,1,vm.formCol2)
                        } else{
                          vm.getFormData('','',this.selparam,this.textparam,'','' ,' order by idseq desc');    
                        }
                    }else{
                        this.$Message.error(res.data.result)
                    }        
                }) 
            } ,
            //获取表数据
            getFormData(v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_){
                var vm=this
                this.loading =true
                getTabColOrData(this.v_likeurl,this.v_username,this.v_password,'V_SYS_FORM',v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_).then((res) => {
                    vm.baseData2 = res.data.result
                        //保存取到 所有数据
                        /*vm.allData =res.data.result
                        vm.dataCount =res.data.result.length //总条数
                        // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
                        if (res.data.result.length < vm.pageSize){
                            vm.baseData2 = vm.allData
                        }else{
                            vm.baseData2 =vm.allData.slice(0,vm.pageSize) //返回选定元素的子数组，不包含结尾元素
                        } */

                    this.loading =false
                    if(res.data.result.length>0){
                        this.rowIdseq =res.data.result[0].IDSEQ   
                        this.getGenData('','','','','','',' and idseq='+this.rowIdseq )         
                    }else{
                        this.getGenData('','','','','','',' and idseq=-1') 
                    } 
                });               
            },
            //获取表列头字段
            getTabCol2(){
                var vm=this
                getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','18','fty','HEAD2','thide','N',' order by sortby,lstseq').then((res) => {
                    vm.tabCol2 = res.data
                    vm.tabCol2.unshift({ //向数组的开头 添加序列号
                        title: '序号',
                        width:60,
                        render:(h,params)=>{
                            return h("span" ,{
                            },params.index+(this.pageNum- 1) * this.pageSize + 1)
                        }
                    })
                });
            },
            //获取表数据
            getGenData(v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_){
                var vm=this
                getTabColOrData(this.v_likeurl,this.v_username,this.v_password,'V_SYS_FORM_GEN',v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_).then((res) => {
                     vm.genData = res.data.result
                });               
            },
            //获取表列头字段
            getGenCol(){
                var vm=this
                getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','18','fty','HEAD4','thide','N',' order by sortby,lstseq').then((res) => {
                    vm.genCol = res.data
                });
            },
            getGenCol2(){
                var vm=this
                getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','18','fty','HEAD4','fhide','N',' order by sortby,lstseq').then((res) => {
                    vm.genFCol = res.data
                });
            },
            //表格页面导航
            changePage(index){
                var _start = ( index - 1 ) * this.pageSize;
                var _end = index * this.pageSize;
                this.pageNum =index
                this.baseData2 = this.allData.slice(_start,_end);
            },
            //获取表行数据
            rowClick2 (row, index) {
                this.formCol2 = row //获取行数据
                this.rowIndex =index
                this.rowIdseq =row.IDSEQ
                this.getGenData('','','','','','',' and idseq='+this.rowIdseq)
            },
            //获取表行数据2
            rowClick3 (row, index) {
                this.genFormCol = row //获取行数据
                this.rowIndex2 =index
                this.rowIdseq2 =row.IDSEQ
                this.lstseq=row.LSTSEQ
            },
            sortChanged2(column){
                //console.log(column.key+';'+column.order)
               // if (column.order!=='normal')
                //this.getFormData(this.selparam,this.textparam,'','','','' ,' order by '+column.key+' '+column.order);
            },
            //明细 新增、修改、删除
            addRec2(){
                if (this.rowIndex==='-1'){
                    this.$Message.error('请选择一条主记录')
                }else{
                    this.genModal =true
                    this.type2 ='create'
                    this.$refs.gentable.clearCurrentRow()
                    this.genFormCol ={'IDSEQ':this.rowIdseq}
                    this.rowIndex2 ='-1'
                }
            },
            editRec2(){
                if (this.rowIndex2==='-1'){
                    this.$Message.error('请选择一条记录')
                }else{
                    this.genModal =true
                    this.type2 ='edit'
                }
            },
            delRec2(){
                if (this.rowIdseq2==='-1'){
                    this.$Message.error('请选择一条记录')
                }else{
                    this.$Modal.confirm({
                        title: '删除确认',
                        content: '<p>你确定要删除此行记录吗</p>',
                        onOk: () => {
                            //发起删除请求
                            var vm=this;
                            this.$axios({
                                method: 'post',
                                url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                                data: {p_key:'idseq',p_value:this.rowIdseq2,p_key2:'lstseq',p_value2:this.lstseq,p_table:'sys_form_gen'},
                            }).then(res=>{      
                                if(res.data.code =='200')  {      
                                    vm.genData.splice(vm.rowIndex2,1 )      
                                }else{
                                    this.$Message.error(res.data.message)
                                }        
                            }) 
                        }
                    });
                }
            },
            //主表 新增、修改、删除
            addRec(){
                this.addModal2 =true
                this.type ='create'
                this.$refs.frmtable.clearCurrentRow()
                this.formCol2 ={}
                this.rowIndex ='-1'
            },
            editRec(){
                if (this.rowIndex==='-1'){
                    this.$Message.error('请选择一条记录')
                }else{
                    this.addModal2 =true
                    this.type ='edit'
                }
            },
            delRec(){
                if (this.rowIdseq==='-1'){
                    this.$Message.error('请选择一条记录')
                }else{
                    this.$Modal.confirm({
                        title: '删除确认',
                        content: '<p>你确定要删除此行记录吗</p>',
                        onOk: () => {
                            //发起删除请求
                            var vm=this;
                            this.$axios({
                                method: 'post',
                                url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                                data: {p_key:'idseq',p_value:this.rowIdseq,p_table:'sys_form'},
                            }).then(res=>{      
                                if(res.data.code =='200')  {          
                                    vm.getFormData(this.selparam,this.textparam,'','','','','' );     
                                }else{
                                    this.$Message.error(res.data.message)
                                }        
                            }) 
                        }
                    });
                }
            },
            qryTabData(){
                this.getFormData('','',this.selparam,this.textparam,'','','' )
                this.chkval ='ALL'
            },
            elchange(val){
                let obj ={}
                obj =this.tabCol2.find(item=>{
                    return item.key===val
                })
                this.input_hold='请输入'+obj.title+' 的值'
            }
        }
    }
</script>
<style lang="less">
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    .item-three  {
        width: 32%;
        color:#409EFF;
    }
</style> 

 
