<template>
    <Layout>
        <Content>  
            <el-table  :data="baseData3" 
                    border                            
                    disabled-hover
                    style="overflow:auto"
                    size="small"
                    ref="modref"
                    highlight-current-row
                    @row-click="rowClick3"
                    :height="300" >
 
                    <el-table-column  v-for="(item,index ) in tabCol3" :key="index"
                        :prop="item.FLDNO"
                        :label="item.FLDNM"
                        show-overflow-tooltip
                        :align="item.ALIGN"                                  
                        :sortable="item.key!=='SORTBY'&&item.EDCSS!=='checkbox'"
                        :width="item.width">
                        <template slot-scope="scope" >
                            <el-checkbox v-if ="item.EDCSS==='checkbox'" disabled false-label="N" true-label="Y" v-model="scope.row[item.FLDNO]" >&nbsp;</el-checkbox>
                            <span v-else v-text="scope.row[item.FLDNO]"></span>
                        </template>
                    </el-table-column>
 
            </el-table>
            <div style="margin-top:1px;" class="one-toolbar"> 
                <span class="spanRow">
                    <el-button type="text" size="small" @click="addRec()"  ><i class="el-icon-circle-plus" style="margin-right:5px;color:white"></i><span style="color:white">新增</span></el-button>
                    <el-button type="text" size="small"  @click="editRec()"  ><i class="el-icon-edit" style="margin-right:5px;color:white"></i><span style="color:white">修改</span></el-button>
                    <el-button type="text" size="small"  @click="delRec()"  ><i class="el-icon-delete" style="margin-right:5px;color:white"></i><span style="color:white">删除</span></el-button>                      
                    <el-input :placeholder="input_hold" v-model="textparam" @keyup.enter.native="qryTabData" size="small" style="width:350px;margin-left:10px;vertical-align:center">
                        <el-select v-model="selparam" slot="prepend" placeholder="请选择列名" clearable @change="elchange" style="width:150px; ">
                            <span v-for="(item ,index) in tabCol4" :key='index'>
                                <el-option :label="item.title" :value="item.key"></el-option>
                            </span>
                        </el-select>
                    </el-input> 
                </span>
            </div>
            <Table  :data="baseData4" 
                    :columns="tabCol4" 
                    border                            
                    disabled-hover
                    style="overflow:auto;margin-top:1px;"
                    :loading="loading2"
                    size="small"
                    ref="modfrm"
                    highlight-row
                    @on-row-click="rowClick4"
                    :height="tabHeight"
 
                    class="small-table" 
                    >
            </Table>
            <el-dialog  :show-close="false" v-dialogDrag :visible.sync="addModal3" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
                <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
                    <span style="line-height:40px;height:40px;font-size:16px;padding-left:5px;">{{type==='create'?'新增记录':'编辑记录'}}</span>
                </div>
 
                <Form ref="addForm" :model="formCol4" :label-width="100"  :rules="ruleValidate"  label-position="right" inline >
                    <span v-for="(item,index) in tabCol4" :key="index" >
                        <FormItem :label="index==0?'序号':item.FLDNM" :prop="item.FLDNO" v-if="item.FHIDE==='N'" class="item-three">                                  
                            <el-select v-if="item.EDCSS==='select'" filterable v-model="formCol4[item.FLDNO]" placeholder="请选择" clearable style="width:100%" @on-change="selChange">
                                <el-option v-for="(item,index) in formArr" :label="item.FRMNM" :value="item.IDSEQ" :key="index"></el-option>
                            </el-select>
                            <el-input  v-else type="text" :disabled="item.DISABLED=='Y'?true:false||index==0" v-model="formCol4[item.FLDNO]"   clearable/>
                        </FormItem>
                    </span>
                </Form>
                <div slot="footer">
                    <el-button type="info" size="small"  @click="modal_cancel" style="margin-right:30px">取消</el-button>
                    <el-button type="primary" size="small"  @click="modal_ok">确定</el-button>
                </div>
            </el-dialog>  
        </Content>
    </Layout>
</template>
<script>
import { getTabColOrData } from '../../api/user';
export default{  
    data () {
            return {
                addModal3:false, //modal 
                textparam:'', // input 绑定值
                selparam:'',// select 绑定值
                tabCol3:[] ,//表列集合
                tabCol4:[] ,//表列集合
                input_hold:'请输入内容...',
                baseData3:[] ,//表数据
                baseData4:[] ,//表数据
                formArr:[] , //保存取到所有数据
                formCol4:{}, //表格单行记录 model
                rowIndex:'-1', //行号 
                rowIndex2:'-1', //行号 
                // 初始化信息总条数
                type:'create',
                loading:false,// table加载bool
                loading2:false,
                rowIdseq:'-1',
                rowIdseq2:'-1',
                modseq:'-1',
                rowModnm:'',
                ruleValidate: {},
                notNull:[],
                stfgBool:true,
                v_url:this.$store.state.baseUrl+'sysprivs/getTableRecMany', //api请求路径
                v_likeurl:this.$store.state.baseUrl+'sysprivs/getLikeManyRec', //多记录查询 like模式
                v_username:this.$cookies.get('v_username'), //api 请求用户
                v_password:this.$cookies.get('v_password') ,//api 请求密码
            }
        },
        watch: {
          modseq(){
              this.getModFrmData('','','','','','',' and modidseq='+this.rowIdseq)
 
          }  
        },
        computed: {
            tabHeight(){
                return (window.innerHeight - 460) +'px'
            }               
        },
        created () {
            this.getModData('','','','','','','')          
            this.getTabCol()
            this.getTabCol2()
            this.getFormData()
        },
        mounted () {
            //动态生成表单验证规则
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','18','fty','HEAD3','NOTNULL','Y').then((res) => {
                let ruleObj={}
                let ruleVal=[]
                this.notNull = res.data
                res.data.forEach(item => {
                    let v_fldnm =item.FLDNM
                    let obj1={required:true,message:v_fldnm+'不能为空'}
                    ruleVal =[]
                    ruleVal.push(obj1)
                    ruleObj[item.FLDNO]=ruleVal                  
                });
                this.ruleValidate =ruleObj
            });
        },
        methods: {
            modal_cancel(){
                this.addModal3=false
                this.rowIdseq2 ='-1'
                this.$refs.modfrm.clearCurrentRow()
            },
            //确定
            modal_ok(){
                var vm=this;
                this.notNull.some(item=>{  //forEach 没找到跳出循环，some：return true 跳出lop ,every：return false 跳出
                    if (!this.formCol4[item.FLDNO] ){
                       this.stfgBool =false 
                       this.$Message.error(item.FLDNM+'不能为空'); 
                       return true
                    }else{
                      this.stfgBool =true  
                    }
                })
                //发起保存请求   
                if (this.stfgBool)       
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/formOneSave?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_json:JSON.stringify(vm.formCol4), p_table:'sys_mod_form'},
                }).then(res=>{      
                    if(res.data.result =='OK')  {          
                        vm.addModal3 =false  
                        if (vm.type==='edit'){
                            vm.baseData4.splice(vm.rowIndex2,1,vm.formCol4)
                        } else{
                          vm.baseData4.unshift( vm.formCol4)   
                        }
                    }else{
                        this.$Message.error(res.data.result)
                    }        
                }) 
            },
            addRec(){
                this.addModal3 =true
                this.type ='create'
                this.formCol4 ={'MODIDSEQ':this.rowIdseq,'MODNM':this.rowModnm}
                this.rowIndex2 ='-1'
            },
            editRec(){
                if (this.rowIdseq2==='-1'){
                    this.$Message.error('请选择一条记录')
                }else{
                    this.addModal3 =true
                    this.type ='edit'
                }
            },
            delRec(){
                if (this.rowIdseq2==='-1'){
                    this.$Message.error('请选择一条记录')
                }else{
                    this.$Modal.confirm({
                        title: '删除确认',
                        content: '<p>你确定要删除此行记录吗</p>',
                        onOk: () => {
                            //发起删除请求
                            var vm=this;
                            this.$axios({
                                method: 'post',
                                url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                                data: {p_key:'idseq',p_value:this.rowIdseq2,p_table:'sys_MOD_form'},
                            }).then(res=>{      
                                if(res.data.code =='200')  {      
                                    vm.baseData4.splice(vm.rowIndex2,1 )      
                                }else{
                                    this.$Message.error(res.data.message)
                                }        
                            }) 
                        }
                    });
                }
            },
            elchange(val){
                let obj ={}
                obj =this.tabCol4.find(item=>{
                    return item.key===val
                })
                this.input_hold='请输入'+obj.title+' 的值'
            },
            selChange(val){
                let obj ={}
                obj =this.formArr.find(item=>{
                    return item.IDSEQ===val
                })
                if (obj)
                this.formCol4.FRMNM =obj.FRMNM
            },
            qryTabData(){
                this.getModFrmData('','',this.selparam,this.textparam,'','',' and modidseq='+this.rowIdseq)
            },
 
            //获取表行数据
            rowClick3 (row, index) {               
                this.rowIndex =index
                this.rowIdseq =row.IDSEQ
                this.rowModnm =row.MODNM
                this.getModFrmData('','','','','','',' and modidseq='+this.rowIdseq)
            },
            rowClick4 (row, index) {
                this.formCol4 = row //获取行数据
                this.rowIndex2 =index
                this.rowIdseq2 =row.IDSEQ
            },
            //获取模块数据
            getModData(v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_){
                var vm=this
                this.loading =true
                getTabColOrData(this.v_likeurl,this.v_username,this.v_password,'V_SYS_MODULE',v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_).then((res) => {
                    vm.baseData3 = res.data.result
                    this.loading =false
                    if(res.data.result.length>0){
                        this.rowIdseq =res.data.result[0].IDSEQ  
                        vm.modseq =this.rowIdseq
                        vm.rowModnm = res.data.result[0].MODNM  
                        res.data.result[0]["_highlight"]=true
                    }
                });               
            },
            //获取模块表单数据
            getModFrmData(v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_){
                var vm=this
                this.loading2 =true
                getTabColOrData(this.v_likeurl,this.v_username,this.v_password,'V_SYS_MODULE_FORM',v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_).then((res) => {
                    vm.baseData4 = res.data.result
                    this.loading2 =false
                });               
            },
            //获取表列头字段
            getTabCol(){
                var vm=this
                getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','18','fty','HEAD','thide','N',' order by sortby').then((res) => {
                    vm.tabCol3 = res.data
                });
            },
            //获取表列头字段
            getTabCol2(){
                var vm=this
                getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','18','fty','HEAD3','thide','N').then((res) => {
                    vm.tabCol4 = res.data
                    if (res.data.length>0 )
                    vm.tabCol4.unshift({ //向数组的开头 添加序列号
                        title: '序号',
                        FHIDE:'N',
                        EDCSS:'input',
                        width:60,
                        render:(h,params)=>{
                            return h("span" ,{
                            },params.index+ 1)
                        }
                    })
                });
            },
            //获取表单资料
            getFormData(){
                var vm=this
                getTabColOrData(this.v_likeurl,this.v_username,this.v_password,'V_SYS_FORM','','','','','','',' and frmurl is not null').then((res) => {
                    vm.formArr = res.data.result
                });               
            },
        }
}
</script>
<style lang="less">
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    .item-three  {
        width: 48%;
        color:#409EFF;
    }
</style>     